<template>
  <div class="container">
    <div>
      <div>
        <header class="modal-card-head">
          <p class="modal-card-title">Merci {{ user.name }} !</p>
        </header>
        <section class="modal-card-body">
          <p class="title is-6">Commande n° {{ order }}</p>
          <b-field>
            Votre commande a été confirmée. Nous vous informerons quand elle sera prête.
          </b-field>
          <b-field>
            Vous pouvez payer sur place ou maintenant avec votre carte de crédit
          </b-field>
          <b-field>
            Informations client :
          </b-field>
          <b-field>
            Nom : {{ user.name }}
          </b-field>
          <b-field>
            Email : {{ user.email }}
          </b-field>
        </section>
      </div>
      <section class="modal-card-body">
        <header>
          <p class="modal-card-title">Résumé de votre commande</p>
        </header>
        <div class="card-content">
          <b-field>
            Nombre de produits commandés : {{ nb }}
          </b-field>
          <b-field>
            Total de la commande : {{ Number(total).toFixed(2) }} CHF
          </b-field>
          <b-field>
            Vous pouvez payer en magasin ou via Paypal
          </b-field>
          <b-field class="has-text-weight-bold">
            Frais paypal équivalent à 0,55CHF + 3,4% <br>
          </b-field>
        </div>
        <div v-show="paidFor">
          <h1>Merci pour votre paiement</h1>
        </div>
        <div style="position: relative; z-index: 1;">
          <div v-show="!paidFor" ref="paypal"></div>
        </div>
        <b-field>
          <b-button type="is-success" @click="$router.push({ name: 'Home' })">Retourner à l'accueil</b-button>
        </b-field>
      </section>
    </div>
    <br>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'RegisterForm',
  data: () => ({
    nb: 0,
    total: 0,
    user: {},
    order: 0,
    form: {
      name: null,
      password: null,
      email: null
    },
    loaded: false,
    paidFor: false,
  }),
  computed: {
    ...mapGetters(['myProducts', 'nbMyProducts', 'myTotalPrice'])
  },
  beforeMount () {
    this.$store.dispatch('getUser')
      .then((data) => {
        this.user = data;
        this.form = this.user;
      })
      .catch(() => {
        this.showError('Impossible de charger le compte.')
        this.$store.dispatch('logout')
      });
    this.nb = this.nbMyProducts
    this.total = this.myTotalPrice
    this.order = this.$store.getters.myOrder
    this.$store.commit('removeOrder')
    this.$store.commit('EMPTY_CART')
  },
  mounted: function () {
    const script = document.createElement('script')
    script.src = 'https://www.paypal.com/sdk/js?currency=CHF&client-id=AXzEHueFn4Lj8N15C4VEgAaVrqriil9pfITwCy4e0vOag2Nf74FkEIVj48z7yibf10JdXlVCHgl07QYc'
    script.addEventListener('load', this.setLoaded)
    document.body.appendChild(script)
  },
  methods: {
    setLoaded: function () {
      this.loaded = true
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: 'Commande à ARENE DE DUEL numéro : ' + this.order,
                  amount: {
                    value: Number((this.total * 1.034 + 0.55)).toFixed(2),
                    currency_code: 'CHF'
                  }
                }
              ]
            })
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture()
            this.paidFor = true
            this.$store.dispatch('payOrder', this.order)
          }
        })
        .render(this.$refs.paypal)
    }
  }
}
</script>
